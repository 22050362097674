/* .typewriter {
  width: 400px;
  text-align: center;
  display: flex;
}
/* @media screen and (min-width: 420px) {
  .typewriter {
    font-size: 40px;
  }
}
@media screen and (min-width: 570px) {
  .typewriter {
    font-size: 55px;
  }
}
@media screen and (min-width: 790px) {
  .typewriter {
    font-size: 70px;
  }
}
@media screen and (min-width: 920px) {
  .typewriter {
    font-size: 85px;
  }
} */

/* .sentence:not(.last-sentence) {
  display: block;
} */

/* .last-sentence {
  width: 500px;
  white-space: nowrap;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: #ddc2ff;
}

.dynamic-text {
  position: relative;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.03em;
  color: #ffe8f5; */
/* } */
/* .dynamic-text:after {
  content: "|";
  position: absolute;
  left: calc(100% - 5px);
}
.dynamic-text.fade:after {
  animation: fade 1s infinite ease-in-out;
} */

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* .text-heading {
  color: red;
  font-size: 30px;
} */

.cursor {
  border-left: 0.1em solid #ffe8f5;
  animation: blink 0.7s steps(1) infinite ease-in-out;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
