.rdrDateRangePickerWrapper {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
  border-radius: 8px !important;
  padding: 10px !important;
}

.rdrDateDisplayWrapper {
  background-color: #ffffff !important;
  border-radius: 8px !important;
}

.rdrStartEdge {
  background-color: #3eacf6 !important;
}
.rdrInRange {
  background-color: #78c9ff !important;
}
.rdrEndEdge {
  background-color: #78c9ff !important;
}
.rdrDateDisplayItemActive {
  border: 1px solid #78c9ff !important;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}
