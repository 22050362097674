.form-control {
  width: 100% !important;
  border: 1px solid rgba(138, 111, 241, 0.45) !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  margin: 20px 0px !important;
  padding: 22px 60px !important;
  font-family: "Nunito Sans" !important;
  font-style: normal !important;
  font-size: 16px !important;
  color: #3a1ba3 !important;
}

.form-control::placeholder {
  color: rgba(58, 27, 163, 0.3) !important;
}

.flag-dropdown {
  border-radius: 0px !important;
  border: 1px solid rgba(138, 111, 241, 0.45) !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.selected-flag {
  background-color: #ffffff !important;
  border: none !important;
  width: 50px !important;
}

.flag {
  left: 28% !important;
}

@media only screen and (min-width: 481px) {
  .form-control {
    width: 100% !important;
    padding: 0px 60px !important;
  }
}

@media only screen and (max-width: 480px) {
  .form-control {
    width: 100% !important;
    padding: 0px 60px !important;
    font-size: 10px !important;
  }
  .selected-flag {
    background-color: #ffffff !important;
    border: none !important;
    width: 50px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .form-control {
    width: 100% !important;
    border: 1px solid rgba(138, 111, 241, 0.45) !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    margin: 20px 0px !important;
    padding: 0px 60px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .form-control {
    width: 100% !important;
    border: 1px solid rgba(138, 111, 241, 0.45) !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    margin: 20px 0px !important;
    padding: 20px 60px !important;
  }
}
