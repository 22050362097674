.spinner * {
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  background-color: #434484;
  border-radius: 1rem;
  margin: 0 0.2rem;
}
.spinner .spinner1 {
  animation: fade 1.1s ease-in-out 0.18s infinite both;
}
.spinner .spinner2 {
  animation: fade 1.1s ease-in-out 0.36s infinite both;
}
.spinner .spinner3 {
  animation: fade 1.1s ease-in-out 0.54s infinite both;
}

@keyframes fade {
  0% {
    opacity: 0.35;
    transform: scale(0.87);
  }
  37.5% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 0.35;
    transform: scale(0.87);
  }
  100% {
    opacity: 0.35;
    transform: scale(0.87);
  }
}
