.dateContainer input {
  width: 170px;
  height: 40px;
  border: 1px solid #434484;
  background: transparent;
  text-align: center;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #434484;
}

.dateContainer label {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #434484;
  margin-right: 15px;
}

.dateContainer ::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-image: url("../../assets/images/calendar.png");
  width: 24px;
  height: 24px;
  cursor: pointer;
}
