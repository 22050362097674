.frequency[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  width: 15px;
  height: 15px;
  border: 0.5px solid #3a1ba3;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 0.5rem;
}
.frequency[type="checkbox"]:active {
  box-shadow: inset 0 0 0 0.125rem #3a1ba3;
}
.frequency[type="checkbox"]:checked {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M0,0H24V24H0Z" fill="none"/><path d="M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z" fill="rgb(58, 27, 163)"/></svg>');
  background-position: center;
  box-shadow: none;
}
.frequency[type="checkbox"]:checked:active {
  opacity: 0.9;
}
.frequency[type="checkbox"]::before {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  background-color: rgba(41, 98, 255, 0);
  position: absolute;
  border-radius: 50%;
  transform: scale(0.5);
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  will-change: transform background-color;
}

.time[type="time"] {
  border: none;
  color: #3a1ba3;
  font-size: 20px;
  width: 100px;
  background: transparent;
}

.time[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  width: 24px;
  height: 24px;
  filter: invert(17%) sepia(43%) saturate(6829%) hue-rotate(252deg)
    brightness(69%) contrast(105%);
}
