.customModal {
  background: #ffffff;
  border-radius: 20px;
  max-width: 1100px;
  width: auto;
  padding: 0px;
  animation-fill-mode: forwards !important;
}

@media screen and (max-width: 1024px) {
  .customModal {
    background: #ffffff;
    border-radius: 24px;
    max-width: 740px;
    width: 70%;
    animation-fill-mode: forwards !important;
  }
}

@media screen and (max-width: 768px) {
  .customModal {
    background: #ffffff;
    border-radius: 24px;
    max-width: 600px;
    width: 50%;
    animation-fill-mode: forwards !important;
  }
}

@media screen and (max-width: 450px) {
  .customModal {
    background: #ffffff;
    border-radius: 24px;
    max-width: 400px;
    width: 90%;
    animation-fill-mode: forwards !important;
  }
}

.react-responsive-modal-closeButton svg {
  fill: rgba(67, 68, 132, 1) !important;
}
.react-responsive-modal-closeButton:focus {
  border: none !important;
  outline: none !important;
}
.react-responsive-modal {
  animation-fill-mode: forwards !important;
}
