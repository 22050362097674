.sp {
  width: 20px;
  height: 20px;
  clear: both;
}

/* Spinner Circle Rotation */
.sp-circle {
  border: 4px rgba(164, 190, 255, 0.25) solid;
  border-top: 4px #ffffff solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.6s infinite linear;
}

@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spCircRot {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
