.Toastify__toast--success {
  background: #549202 !important;
  font-family: "Nunito Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff !important;
}

.Toastify__toast--error {
  background: #e8505b !important;
  font-family: "Nunito Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff !important;
}

.Toastify__toast--info {
  font-family: "Nunito Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff !important;
}
